import React from 'react';
import GoogleLogin from "react-google-login";
import queryString  from 'query-string';

import './App.css';

const trad = {
    'cn': '用谷歌登录',
    'de': 'Loggen Sie sich mit Google ein',
    'en': 'Login with google',
    'es': 'Inicia sesión con google',
    'fr': 'Connectez-vous avec google',
    'ja': 'グーグルでログイン',
    'it': 'Accedi con google',
    'nl': 'Log in met Google',
    'pt': 'Entre com o google',
    'ru': 'Войти с помощью Google'
};

function App() {

    const responseGoogle = (payload) => {
        console.log(55555);
        console.log(payload);
        const data = {
            token: payload.accessToken
        }
        window.parent.postMessage(data, '*');
    }

    const failureGoogle = (payload) => {
        console.log('FAILURE');
        console.log(payload);
    }

    const parsed = queryString.parse(document.location.search);
  

    let lang = 'en';
    if(parsed && parsed.lang) {
        lang = parsed.lang;
    }


    let title = trad['en'];
    if(trad[lang]) {
        title = trad[lang];
    }

  
  return (
    <div className="App">
      <GoogleLogin
        clientId="317499238090-2al8rnu7c07bbbe6ln9pdkeavr5jd3b9.apps.googleusercontent.com"
        buttonText={title}
        onSuccess={responseGoogle}
        onFailure={failureGoogle}
        //className={classes.root}
        //style={classes.root}
      />
    </div>
  );
}

export default App;
